import React from 'react'
import CardFeed from '../components/cottagecards/CardFeed'
import { useLocation,} from 'react-router-dom';

function Availability() {
  const location = useLocation();
  const data = location.state;
  
  return (
    <div>
         {/* Header Section */}
      <div className='w-full h-[30vh] md:h-[380px] flex flex-col' style={{
        backgroundImage: `url("https://d6pltnqzug32h.cloudfront.net/avail.jpeg")`,
        backgroundSize: 'cover',
        backgroundPosition: 'bottom',
        backgroundRepeat: 'no-repeat',
      }}>
        <div className=' h-full w-full bg-black/15 items-center justify-center flex flex-col'>
          <div className='w-full h-full flex flex-col items-center justify-center'>
            <div className='text-center text-2xl mt-[70px] text-white
                        '>
              <p className='md:text-6xl text-4xl lg:text-8xl text-white pattaya'>Availability</p>
            </div>
          </div>

        </div>
      </div>
      <div className='container mx-auto'>
            <CardFeed avail={data}/>
      </div>
    </div>
  )
}

export default Availability