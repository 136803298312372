import React from 'react'
import Marquee from "react-fast-marquee";
import agoda from '../asset/agoda.png';
import airbnb from '../asset/airbnb.png';
import booking from '../asset/booking.webp';
import expedia from '../asset/expedia.png';
import tripadvisor from '../asset/tripadvisor.png';
import mmt from "../asset/mmt.webp";
import trivago from  "../asset/trivago.png";
import goibibo from  "../asset/goibibo-logo.png";


function MarqueeCus() {

  const images = [
    agoda,
    airbnb,
    booking,
    mmt,
    agoda,
    airbnb,
    booking,
    mmt,
    agoda,
    airbnb,
    booking,
    mmt,
    ];
  return (
    <div className='w-full flex flex-col justify-center items-center mb-[40px]'>
      <p className='text-4xl pattaya text-red-600 mb-[40px]'>Our Partners</p>
      <Marquee className='mb-[40px]'>
        {images.map((image, index) => (
          <img
          key={index}
          src={image}
            alt="logo"
            className="h-[50px] object-contain ml-[50px]"
            />
            ))}
      </Marquee>
    </div>
  )
}

export default MarqueeCus