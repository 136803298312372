
export const cottageData = {
    studio_apartment: {
        title: "Studio Apartment",
        plot:"IVH 69 - F1",
        maxguest: 2,
        extbed:1,
        images: [
          "https://d2ehq5aws28ia0.cloudfront.net/project/5.jpg",
            "https://d2ehq5aws28ia0.cloudfront.net/plots/plot69/1.jpg",
            "https://d2ehq5aws28ia0.cloudfront.net/plots/plot69/2.jpg",
            "https://d2ehq5aws28ia0.cloudfront.net/plots/plot69/3.jpg",
            "https://d2ehq5aws28ia0.cloudfront.net/plots/plot69/4.jpg",
            "https://d2ehq5aws28ia0.cloudfront.net/plots/plot69/5.jpg",
            "https://d2ehq5aws28ia0.cloudfront.net/plots/plot69/6.jpg",
            "https://d2ehq5aws28ia0.cloudfront.net/plots/plot69/7.jpg",
            "https://d2ehq5aws28ia0.cloudfront.net/plots/plot69/8.jpg",
        ],
        summary:"",
        price: "2000",
    },
    studio_apartmentf2: {
      title: "Studio Apartment",
      plot:"IVH 69 - F2",
      maxguest: 2,
      extbed:1,
      images: [

        "https://d6pltnqzug32h.cloudfront.net/cottages/f2/7.jpg",
        "https://d6pltnqzug32h.cloudfront.net/cottages/f2/8.jpg",
        "https://d6pltnqzug32h.cloudfront.net/cottages/f2/6.jpg",
        "https://d6pltnqzug32h.cloudfront.net/cottages/f2/5.jpg",
        "https://d2ehq5aws28ia0.cloudfront.net/himalayan/1.jpg",
          "https://d6pltnqzug32h.cloudfront.net/cottages/f2/1.jpg",
          "https://d6pltnqzug32h.cloudfront.net/cottages/f2/2.jpg",
          "https://d6pltnqzug32h.cloudfront.net/cottages/f2/3.jpg",
          "https://d6pltnqzug32h.cloudfront.net/cottages/f2/4.jpg",
          "https://d2ehq5aws28ia0.cloudfront.net/project/5.jpg",

      ],
      summary:"",
      price: "2000",
  },
  studio_apartmentf4: {
    title: "Studio Apartment",
    plot:"IVH 69 - F4",
    maxguest: 2,
    extbed:1,
    images: [
      "https://d2ehq5aws28ia0.cloudfront.net/project/5.jpg",
      "https://d6pltnqzug32h.cloudfront.net/cottages/f2/7.jpg",
      "https://d6pltnqzug32h.cloudfront.net/cottages/f2/8.jpg",
      "https://d6pltnqzug32h.cloudfront.net/cottages/f2/6.jpg",
      "https://d6pltnqzug32h.cloudfront.net/cottages/f2/5.jpg",
        "https://d6pltnqzug32h.cloudfront.net/cottages/f2/1.jpg",
        "https://d6pltnqzug32h.cloudfront.net/cottages/f2/2.jpg",
        "https://d6pltnqzug32h.cloudfront.net/cottages/f2/3.jpg",
        "https://d6pltnqzug32h.cloudfront.net/cottages/f2/4.jpg",
        "https://d6pltnqzug32h.cloudfront.net/cottages/3.jpg",
    ],
    summary:"",
    price: "2000",
},
    "2BHK_villa": {
        title: "2BHK Villa",
        maxguest: 4,
        extbed:2,
        plot:"IVH 47",
        images: [
            "https://d6pltnqzug32h.cloudfront.net/cottages/cot47/5.jpg",
            "https://d2ehq5aws28ia0.cloudfront.net/himalayan/6.jpg",
          "https://d6pltnqzug32h.cloudfront.net/cottages/cot47/6.jpg",
          "https://d6pltnqzug32h.cloudfront.net/cottages/cot47/1.jpg",
          "https://d2ehq5aws28ia0.cloudfront.net/project/21.jpg",
          "https://d6pltnqzug32h.cloudfront.net/cottages/cot47/10.jpg",
          "https://d6pltnqzug32h.cloudfront.net/cottages/cot47/11.jpg",
          "https://d6pltnqzug32h.cloudfront.net/cottages/cot47/2.jpg",
          "https://d6pltnqzug32h.cloudfront.net/cottages/cot47/3.jpg",
          "https://d6pltnqzug32h.cloudfront.net/cottages/cot47/4.jpg",
          "https://d6pltnqzug32h.cloudfront.net/cottages/cot47/7.jpg",

        ],
        summary:"Escape to our 2BHK Nanda Devi View Villa - a perfect blend of modern comfort and breathtaking nature. Wake up to panoramic views, our 2 BHK family room features 2 bedrooms and a separate bathroom with a shower, and living area and a dining area with a kitchenette, a wardrobe, an electric kettle as well as a garden with mountain views. relax in spacious living areas, and indulge in the serenity of your private haven, you can enjoy your night with a bonfire and live barbeque in the garden. Book now for an unforgettable hillside retreat!",
        price: "7000",
    },
    "1BHK_Apartment": {
        title: "1BHK Apartment",
        maxguest: 2,
        extbed:1,
        plot:"IVH 09",
        "images": [
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot09/1.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot09/2.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/project/2.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot09/3.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot09/6.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot09/8.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot09/12.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot09/10.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot09/9.jpg",
    ],
        summary:"Escape to our 2BHK Nanda Devi View Villa - a perfect blend of modern comfort and breathtaking nature. Wake up to panoramic views, our 2 BHK family room features 2 bedrooms and a separate bathroom with a shower, and living area and a dining area with a kitchenette, a wardrobe, an electric kettle as well as a garden with mountain views. relax in spacious living areas, and indulge in the serenity of your private haven, you can enjoy your night with a bonfire and live barbeque in the garden. Book now for an unforgettable hillside retreat!",
        price: "4000",
    },
    "4BHK_villa": {
        title: "4BHK Villa",
        maxguest: 8,
        extbed:4,
        plot:"IVH 43",
        "images": [
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot43/1.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/drone/1.jpg",
      "https://d6pltnqzug32h.cloudfront.net/cottages/2.jpg",
      // "https://d2ehq5aws28ia0.cloudfront.net/plots/plot43/6.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot43/5.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot43/2.jpg",
      // "https://d2ehq5aws28ia0.cloudfront.net/plots/plot43/4.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot43/3.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot43/12.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot43/14.jpg",
      // "https://d2ehq5aws28ia0.cloudfront.net/plots/plot43/15.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot43/16.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot43/7.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot43/8.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot43/9.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot43/10.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot43/11.jpg",
      // "https://d2ehq5aws28ia0.cloudfront.net/plots/plot43/13.jpg",
      // "https://d2ehq5aws28ia0.cloudfront.net/plots/plot43/17.jpg",
    ],
        summary:"The 4 BHK is known as Mountain View Villa. This duplex family room features 4 bedrooms. 2 bedrooms are on the ground floor and offer a separate bathroom with a shower, and living area with sofa cum bed and dining area, a wardrobe, an electric kettle and 2 bedrooms are on the upper floor with dining area and a huge balcony with Himalayan mountain view attached with both rooms, 2 separate gardens, and free personal parking. Experience comfort and elegance in this stylish 4BHK Villa, nestled in a serene location, the villa boasts modern amenities, spacious rooms, and a tranquil ambience. Ideal for a relaxing getaway, this well-appointed accommodation ensures a delightful stay for both leisure and business travellers. You can enjoy your night with a bonfire and live barbeque in the garden. Book now for a memorable retreat!",
        price: "12000",
    },
    "1BHK_villa": {
        title: "1BHK Villa",
        plot:"IVH 42",
        "images": [
          "https://d6pltnqzug32h.cloudfront.net/cottages/42/1.jpg",
          "https://d6pltnqzug32h.cloudfront.net/cottages/42/2.jpg",
          "https://d6pltnqzug32h.cloudfront.net/cottages/5.jpg",
          "https://d6pltnqzug32h.cloudfront.net/cottages/1.jpg",
          "https://d6pltnqzug32h.cloudfront.net/cottages/42/3.jpg",
          "https://d6pltnqzug32h.cloudfront.net/cottages/42/4.jpg",
          "https://d6pltnqzug32h.cloudfront.net/cottages/42/5.jpg",
          "https://d6pltnqzug32h.cloudfront.net/cottages/42/6.jpg",
          "https://d6pltnqzug32h.cloudfront.net/cottages/42/7.jpg",
      
    ],
        summary:"Charm meets the mountains in our 1 BHK Nanda Devi View villa Snuggle up with nature’s beauty, and wake up to mountain magic. The 1 BHK room features 1 bedroom and 1 bathroom with a shower and free toiletries. The 1 BHK room offers a living area with a common bathroom and a dining area with a kitchenette, a wardrobe, and an electric kettle as well as a garden with mountain views. You can enjoy your night with a bonfire and live barbeque in the garden. Your cosy escape awaits - book now for a view that steals hearts!",
        price: "12000",
    },
}

