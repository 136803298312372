import React, { useState, useCallback, useEffect } from 'react';
import { ChevronLeft,Download, ChevronRight } from 'lucide-react';
import { AnimatePresence, motion } from 'framer-motion';

function MenuRes() {
  const [lightboxIndex, setLightboxIndex] = useState(0);

  const images = [
    "https://d6pltnqzug32h.cloudfront.net/menu/1.jpg",
    "https://d6pltnqzug32h.cloudfront.net/menu/2.jpg",
    "https://d6pltnqzug32h.cloudfront.net/menu/3.jpg",
    "https://d6pltnqzug32h.cloudfront.net/menu/4.jpg",
    "https://d6pltnqzug32h.cloudfront.net/menu/5.jpg",
    "https://d6pltnqzug32h.cloudfront.net/menu/6.jpg",
  ];

  useEffect(() => {
    const handleKeyDown = (e) => {
      switch (e.key) {
        case 'ArrowRight':
          nextImage();
          break;
        case 'ArrowLeft':
          prevImage();
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [lightboxIndex]);

  const nextImage = useCallback(() => {
    setLightboxIndex((prevIndex) => (prevIndex + 1) % images.length);
  }, [images]);

  const prevImage = useCallback(() => {
    setLightboxIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  }, [images]);

  return (
    <>
        <AnimatePresence>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="relative h-[100%] flex flex-col gap-5 mt-[20px] items-center justify-center p-4"
        >
          {/* Image Display */}
          <div className="relative w-full flex items-center justify-center">
            <motion.img
              key={lightboxIndex}
              src={images[lightboxIndex]}
              alt={`Menu Image ${lightboxIndex + 1}`}
              className="max-w-[80%] max-h-[80vh] object-contain rounded-xl shadow-lg"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
            />

            {/* Navigation Buttons */}
            <button
              onClick={prevImage}
              className="absolute left-0 md:left-5 top-1/2 transform -translate-y-1/2 bg-gray-200 hover:bg-gray-300 text-gray-700 p-4 rounded-full shadow-lg"
            >
              <ChevronLeft size={32} />
            </button>
            <button
              onClick={nextImage}
              className="absolute right-0 md:right-5 top-1/2 transform -translate-y-1/2 bg-gray-200 hover:bg-gray-300 text-gray-700 p-4 rounded-full shadow-lg"
            >
              <ChevronRight size={32} />
            </button>
          </div>

          {/* Image Counter */}
          <span className="bg-gray-400/60 text-gray-700 text-sm px-4 py-2 rounded-lg shadow">
            {lightboxIndex + 1} / {images.length}
          </span>
          <motion.a
            href={`https://d6pltnqzug32h.cloudfront.net/HOMESTAY+MENU.pdf`}
            target='_blank'
            download
            className="mb-[40px] bg-red-600 hover:bg-red-700 text-white px-6 py-3 rounded-full flex items-center gap-2 shadow-lg transition-transform transform hover:scale-105"
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            <Download size={20} />
            Download Menu
          </motion.a>
        </motion.div>
      </AnimatePresence>
    </>
  );
}

export default MenuRes;
