import React from 'react'

function IconsFloat() {
  return (
    <div className="rounded-md flex flex-col fixed z-40 bottom-0 right-0 gap-5 m-5">
        <a
          href="https://www.google.com/maps/dir//Dhanachuli,State+Highway,+Nainital,+Uttarakhand+263132/@29.39809,79.5933096,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3908d7f7740c0bc9:0xe9baaef9dd824df2!2m2!1d79.675711!2d29.3981153?entry=ttu"
          target="_blank"
          rel="noreferrer"
        >
          <img 
            className="hover:scale-125 transition-all duration-100" 
            width="48" 
            height="48" 
            src="https://img.icons8.com/color/96/marker--v1.png" 
            alt="location"
          />
        </a>
        <a href="tel:+918744989989">
          <img 
            className="hover:scale-125 transition-all duration-100" 
            width="48" 
            height="48" 
            src="https://www.theshubhamgroup.com/static/media/icon_call.092a33d874f46145879d.png" 
            alt="phone"
          />
        </a>
        <a href="https://wa.me/8744989989" target="_blank" rel="noreferrer">
          <img 
            className="hover:scale-125 transition-all duration-100" 
            width="48" 
            height="48" 
            src="https://www.theshubhamgroup.com/static/media/icon_whatsapp.417ad2d4d35da4649b19.png" 
            alt="whatsapp--v1"
          />
        </a>
      </div>
  )
}

export default IconsFloat