import React from 'react';
import Cardcarousel from './Cardcarousel';
import { IndianRupee } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

function CottageCard({ data, avail }) {
  const navigate = useNavigate();

  function rendercottage(e) {
    navigate(`/cottages/${e}`, { state: avail });
  }

  return (
    <div>
      <div className='w-[350px] bg-white rounded-2xl overflow-hidden transition-all duration-300 cursor-pointer'>
        <div className='h-[250px] w-full relative'>
          <Cardcarousel data={data} />
          <div className='absolute top-4 right-4 bg-white/80 px-3 py-1 rounded-full text-sm font-semibold text-gray-700'>
            Featured
          </div>
        </div>

        <div onClick={() => rendercottage(data.title)}>
          <div className='px-6 pt-3'>
            <p className='text-gray-600 mb-4 line-clamp-2'>
              <span className='text-2xl text-gray-800 font-bold'>{data.name}</span><br />
              <span className='text-md text-gray-400 font-bold'>{data.plot}</span>
            </p>

            <div className='flex justify-between items-center mb-6'>
              <button className='px-6 py-3 bg-gradient-to-r from-red-500 to-red-600 text-white rounded-lg shadow-md hover:shadow-lg transition-all duration-300 transform hover:-translate-y-1'>
                Book Now
              </button>
              <div className='flex items-center text-2xl font-bold text-gray-800'>
                <IndianRupee size={24} className='mr-1' />
                {data.price}
                <span className='text-sm text-gray-500 ml-1'>/ night</span>
              </div>
            </div>

            <div className='border-t py-4'>
              <div className='flex justify-between items-center'>
                {data.amenities.map((amenity, index) => (
                  <div
                    key={index}
                    className='flex flex-col items-center text-gray-600 cursor-pointer hover:text-red-500 transition-colors'
                  >
                    <amenity.icon size={28} />
                    <span className='text-xs mt-1'>{amenity.label}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CottageCard;
