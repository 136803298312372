import React, { useState, useEffect, useRef } from 'react';
import { ChevronLeft, ChevronRight, X } from 'lucide-react';
import Gallery from './Gallery';

const ImageCarousel = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [isAutoPlayActive, setIsAutoPlayActive] = useState(true);

  const autoPlayIntervalRef = useRef(null);

  const startAutoPlay = () => {
    if (autoPlayIntervalRef.current) {
      clearInterval(autoPlayIntervalRef.current);
    }

    autoPlayIntervalRef.current = setInterval(() => {
      setCurrentIndex((prevIndex) => 
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 2000);
  };

  const stopAutoPlay = () => {
    if (autoPlayIntervalRef.current) {
      clearInterval(autoPlayIntervalRef.current);
    }
  };

  useEffect(() => {
    startAutoPlay();

    return () => {
      if (autoPlayIntervalRef.current) {
        clearInterval(autoPlayIntervalRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isAutoPlayActive) {
      startAutoPlay();
    } else {
      stopAutoPlay();
    }

    const handleKeyDown = (e) => {
      if (e.key === 'Escape' && isGalleryOpen) {
        closeGallery();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      if (autoPlayIntervalRef.current) {
        clearInterval(autoPlayIntervalRef.current);
      }
    };
  }, [isAutoPlayActive, isGalleryOpen]);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const openGallery = () => {
    setIsGalleryOpen(true);
  };

  const closeGallery = () => {
    setIsGalleryOpen(false);
  };

  return (
    <>
      <div className="">
        <div className="relative aspect-video w-full sm:h-[450px] overflow-hidden rounded-lg md:rounded-xl lg:rounded-[25px]">
          <img 
            src={images[currentIndex]} 
            alt={`Slide ${currentIndex + 1}`} 
            className="w-full h-full object-cover"
          />
          
          <button 
            onClick={() => {
              prevSlide();
              if (isAutoPlayActive) {
                stopAutoPlay();
                startAutoPlay();
              }
            }}
            className="absolute left-2 top-1/2 -translate-y-1/2 bg-white/50 p-2 rounded-full hover:bg-white"
          >
            <ChevronLeft size={20} />
          </button>
          <button 
            onClick={() => {
              nextSlide();
              if (isAutoPlayActive) {
                stopAutoPlay();
                startAutoPlay();
              }
            }}
            className="absolute right-2 top-1/2 -translate-y-1/2 bg-white/50 p-2 rounded-full hover:bg-white"
          >
            <ChevronRight size={20} />
          </button>

          <div className="absolute bottom-4 left-0 right-0 flex justify-end space-x-2 px-4">
            <div className='flex gap-3 text-white rounded-xl glass'>
              <button 
                onClick={openGallery}
                className="h-full px-4 py-2 w-full text-sm sm:text-base bg-black/50 hover:bg-black/70 rounded-xl"
              >
                Show All
              </button>
            </div>
          </div>
        </div>
      </div>

      {isGalleryOpen && (
        <div 
          className="fixed inset-0 z-50 bg-white overflow-y-scroll p-8"
          onClick={(e) => {
            if (e.target === e.currentTarget) {
              closeGallery();
            }
          }}
        >
          <button
            onClick={closeGallery}
            className="fixed z-40 top-6 right-6 text-gray-700 bg-gray-300 hover:bg-gray-400 p-3 rounded-full shadow-lg"
          >
            <X size={24} />
          </button>

          <Gallery images={images} />
        </div>
      )}
    </>
  );
};

export default ImageCarousel;
