import React from 'react';
import Header from '../components/Header';
import WeatherDiff from '../components/WeatherDiff';
import CardFeed from '../components/cottagecards/CardFeed';
import Carousel from '../components/Carousel';

function Home() {
  

  const amenities = [
    {
      image: "https://d6pltnqzug32h.cloudfront.net/amenities/amenities/1.png",
      title: "Secure Gated Community",
      description: "Enjoy peace of mind in our gated community.",
    },
    {
      image: "https://d6pltnqzug32h.cloudfront.net/amenities/amenities/6.png",
      title: "Exclusive Clubhouse",
      description: "Relax and rejuvenate in our premium clubhouse.",
    },
    {
      image: "https://d6pltnqzug32h.cloudfront.net/amenities/amenities/9.png",
      title: "Restaurant",
      description: "Savor delightful meals in our on-site restaurant.",
    },
    {
      image: "https://d6pltnqzug32h.cloudfront.net/amenities/amenities/12.png",
      title: "Room Service",
      description: "24/7 room service for your convenience.",
    },
    {
      image: "https://d6pltnqzug32h.cloudfront.net/amenities/amenities/5.png",
      title: "Complimentary Parking",
      description: "Free and secure parking for all guests.",
    },
    {
      image: "https://d6pltnqzug32h.cloudfront.net/amenities/amenities/3.png",
      title: "Breakfast",
      description: "Start your day with a delicious breakfast.",
    },
    {
      image: "https://d6pltnqzug32h.cloudfront.net/amenities/amenities/7.png",
      title: "Landscaped Gardens",
      description: "Relax amidst our beautifully landscaped gardens.",
    },
    {
      image: "https://d6pltnqzug32h.cloudfront.net/amenities/amenities/2.png",
      title: "Wi-Fi",
      description: "Stay connected with complimentary high-speed Wi-Fi.",
    },
    {
      image: "https://d6pltnqzug32h.cloudfront.net/amenities/amenities/10.png",
      title: "24*7 Security Surveillance",
      description: "Advanced surveillance for your safety.",
    },
    {
      image: "https://d6pltnqzug32h.cloudfront.net/amenities/amenities/11.png",
      title: "Panoramic Himalayan Views",
      description: "Witness breathtaking views of the Himalayas.",
    },
    {
      image: "https://d6pltnqzug32h.cloudfront.net/amenities/amenities/8.png",
      title: "Spacious Family Rooms",
      description: "Comfortable rooms designed for families.",
    },
    {
      image: "https://d6pltnqzug32h.cloudfront.net/amenities/amenities/13.png",
      title: "Couple Friendly Rooms",
      description: "Cozy rooms for a romantic stay.",
    },
  ];

  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, (word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });
  };

  return (
        <>
          <div>
            <Header />
          </div>
          <WeatherDiff />
          <CardFeed />
          <section className="mt-[50px] pb-[50px]">
            <div className="container mx-auto px-4">
              <h2 className="text-5xl font-bold text-start mb-8 pattaya text-red-600">Our Amenities</h2>
              <div className="grid grid-cols-2 md:grid-cols-4 gap-8 mt-[30px]">
                {amenities.map((amenity, index) => (
                  <div
                    key={index}
                    className="flex flex-col hover:scale-125 duration-500 cursor-pointer justify-center items-center text-center"
                  >
                    <img
                      src={amenity.image}
                      alt={amenity.title}
                      className="mb-4 w-20 h-20 object-cover"
                    />
                    <h3 className="text-xl font-semibold mb-2">{toTitleCase(amenity.title)}</h3>
                    <p className="text-gray-600 text-xs">{amenity.description}</p>
                  </div>
                ))}
              </div>
            </div>
          </section>
          <Carousel />
    </>
  );
}

export default Home;