import React, { useEffect, useState } from 'react';
import { Sun, Cloud, CloudRain, ThermometerSun } from 'lucide-react';

function WeatherComparison() {
    const [aqi, setAqi] = useState({ Mukteshwar: null, Delhi: null });
    const [weatherData, setWeatherData] = useState({ mukteshwar: null, delhi: null });

    const locations = {
        mukteshwar: ["29.398189778238443", "79.67566808465519"],
        delhi: ["28.6139", "77.2090"]
    };

    useEffect(() => {
        const fetchWeather = async () => {
            try {
                const apiKey = '89e15739e97fac66b503f44fff952e63';
                const [mukteshwarResponse, delhiResponse, aqimuk, aqidel] = await Promise.all([
                    fetch(`https://api.openweathermap.org/data/2.5/weather?lat=${locations.mukteshwar[0]}&lon=${locations.mukteshwar[1]}&appid=${apiKey}&units=metric`),
                    fetch(`https://api.openweathermap.org/data/2.5/weather?lat=${locations.delhi[0]}&lon=${locations.delhi[1]}&appid=${apiKey}&units=metric`),
                    fetch(`https://api.openweathermap.org/data/2.5/air_pollution?lat=${locations.mukteshwar[0]}&lon=${locations.mukteshwar[1]}&appid=${apiKey}`),
                    fetch(`https://api.openweathermap.org/data/2.5/air_pollution?lat=${locations.delhi[0]}&lon=${locations.delhi[1]}&appid=${apiKey}`)
                ]);

                const mukteshwarData = await mukteshwarResponse.json();
                const delhiData = await delhiResponse.json();
                const aqimukData = await aqimuk.json();
                const aqidelData = await aqidel.json();

                setAqi({
                    Mukteshwar: aqimukData.list[0].components.pm10,
                    Delhi: aqidelData.list[0].components.pm10
                });

                setWeatherData({
                    mukteshwar: mukteshwarData,
                    delhi: delhiData
                });
            } catch (error) {
                console.error("Weather fetch error", error);
            }
        };
        fetchWeather();
    }, []);

    const bgvideo = {
        delhi: {
            day: "https://d2ehq5aws28ia0.cloudfront.net/daydel.mp4",
            evening: "https://d2ehq5aws28ia0.cloudfront.net/sun.mp4",
            night: "https://d2ehq5aws28ia0.cloudfront.net/nightdel.mp4",
        },
        mukteshwar: {
            day: "https://d6pltnqzug32h.cloudfront.net/weather/mukdinn.mp4",
            night: "https://d2ehq5aws28ia0.cloudfront.net/night.mp4",
        }
    };

    const displayvideo = [];
    const displayTime = () => {
        const hours = new Date().getHours();
        if (hours >= 6 && hours < 16) {
            displayvideo.push(bgvideo.delhi.day, bgvideo.mukteshwar.day);
        } else if (hours >= 18 && hours < 24) {
            displayvideo.push(bgvideo.delhi.night, bgvideo.mukteshwar.night);
        } else {
            displayvideo.push(bgvideo.delhi.evening, bgvideo.mukteshwar.night);
        }
    };
    displayTime();

    const getWeatherIcon = (weatherCondition) => {
        if (!weatherCondition) return <ThermometerSun className="md:w-16 md:h-16 w-10 h-10 text-white" />;
        switch (weatherCondition.main) {
            case 'Haze': return <Sun className="md:w-16 md:h-16 w-10 h-10 text-white" />;
            case 'Clouds': return <Cloud className="md:w-16 md:h-16 w-10 h-10 text-white" />;
            case 'Rain': return <CloudRain className="md:w-16 md:h-16 w-10 h-10 text-white" />;
            default: return <ThermometerSun className="md:w-16 md:h-16 w-10 h-10 text-white" />;
        }
    };

    const renderWeatherCard = (location, weatherInfo) => (
        <div className="md:w-[600px] w-[90%] rounded-2xl relative md:h-[300px] h-[200px] overflow-hidden shadow-2xl mb-4">
            <video
                className="absolute h-full w-full object-cover z-0"
                autoPlay
                muted
                loop
            >
                <source src={location === 'Delhi' ? displayvideo[0] : displayvideo[1]} type="video/mp4" />
            </video>

            <div className="absolute w-full h-full bg-black/30 inset-0 z-10"></div>
            <div className="absolute z-20 px-6 md:px-8 flex flex-col justify-around w-full h-full text-white">
                <p className="text-3xl md:text-5xl font-bold text-end tracking-wide">{location}</p>
                <div className="text-center flex items-end justify-between w-full">
                    <div className="flex flex-col justify-center items-center">
                        <p className="text-[80px] md:text-[100px] font-bold leading-none">
                            {weatherInfo ? Math.floor(weatherInfo.main.temp) : ""}°
                        </p>
                        <span className="text-md md:text-xl">Celcius</span>
                    </div>
                    <div>
                        <div className="flex flex-col items-end">
                            {getWeatherIcon(weatherInfo?.weather[0])}
                            {weatherInfo && (
                                <p className="mt-2 text-sm capitalize">{weatherInfo.weather[0].description}</p>
                            )}
                        </div>
                        <div className="mt-4 flex justify-end space-x-4">
                            <div className="text-end">
                                <p className="text-sm">Humidity</p>
                                <p className="font-bold">{weatherInfo?.main.humidity}%</p>
                            </div>
                            <div className="text-end">
                                <p className="text-sm">Wind</p>
                                <p className="font-bold">{Math.round(weatherInfo?.wind.speed)} km/h</p>
                            </div>
                            <div className="text-end">
                                <p className="text-sm">AQI</p>
                                <p className="font-bold">{Math.floor(aqi[location])}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <div className="bgi h-full w-full">
            <div className="container mx-auto text-red-600 text-4xl font-bold pt-10 pb-10 pattaya text-center md:text-start">Weather Difference</div>
            <div className="container mx-auto flex flex-col md:flex-row gap-8 justify-center items-center pb-12">
                {renderWeatherCard('Mukteshwar', weatherData.mukteshwar)}
                {renderWeatherCard('Delhi', weatherData.delhi)}
            </div>
        </div>
    );
}

export default WeatherComparison;
