import React, { useState, useEffect, useRef } from 'react';
import { CalendarDays } from 'lucide-react';

const CustomDatePicker = ({ 
  type = 'from', 
  fromDate = null,
  onDateChange,
  selectedDate: propSelectedDate = new Date()
}) => {
    const [selectedDate, setSelectedDate] = useState(propSelectedDate);
    const [isCalendarOpen, setIsCalendarOpen] = useState(false);
    const [currentMonth, setCurrentMonth] = useState(propSelectedDate.getMonth());
    const [currentYear, setCurrentYear] = useState(propSelectedDate.getFullYear());
    const calendarRef = useRef(null);
    const inputRef = useRef(null);


    useEffect(() => {
        setSelectedDate(propSelectedDate);
        setCurrentMonth(propSelectedDate.getMonth());
        setCurrentYear(propSelectedDate.getFullYear());
    }, [propSelectedDate]);

    // Utility function to generate days in a month
    const getDaysInMonth = (year, month) => {
        return new Date(year, month + 1, 0).getDate();
    };

    // Generate calendar days
    const generateCalendarDays = () => {
        const days = [];
        const daysInMonth = getDaysInMonth(currentYear, currentMonth);
        const firstDayOfMonth = new Date(currentYear, currentMonth, 1).getDay();
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        // Add padding days from previous month
        for (let i = 0; i < firstDayOfMonth; i++) {
            days.push(null);
        }

        // Add days of current month
        for (let day = 1; day <= daysInMonth; day++) {
            const currentDate = new Date(currentYear, currentMonth, day);
            currentDate.setHours(0, 0, 0, 0);
            
            // Constraint for 'to' date picker
            if (type === 'to' && fromDate) {
                // Only allow dates after fromDate
                days.push(currentDate >= fromDate ? day : null);
            } else {
                // Only add the day if it's not before today
                days.push(currentDate >= today ? day : null);
            }
        }

        return days;
    };

    // Generate month names
    const getMonthNames = () => {
        return [
            'January', 'February', 'March', 'April',
            'May', 'June', 'July', 'August',
            'September', 'October', 'November', 'December'
        ];
    };

    // Handle date selection
    const handleDateSelect = (day) => {
        if (day) {
            const newDate = new Date(currentYear, currentMonth, day);
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            
            // Validate date based on picker type
            let isValidDate = false;
            if (type === 'from') {
                // For 'from' date, must not be before today
                isValidDate = newDate >= today;
            } else if (type === 'to') {
                // For 'to' date, must not be before fromDate
                isValidDate = fromDate ? newDate >= fromDate : newDate >= today;
            }

            if (isValidDate) {
                setSelectedDate(newDate);
                setIsCalendarOpen(false);
                
                // Call the parent component's date change handler
                if (onDateChange) {
                    onDateChange(newDate);
                }
            }
        }
    };

    // Navigate months
    const navigateMonth = (direction) => {
        let newMonth = currentMonth + direction;
        let newYear = currentYear;

        if (newMonth < 0) {
            newMonth = 11;
            newYear--;
        } else if (newMonth > 11) {
            newMonth = 0;
            newYear++;
        }

        // Only allow navigation to current or future months
        const today = new Date();
        const targetMonth = new Date(newYear, newMonth);
        
        // Additional check for 'to' date picker
        const minAllowedDate = type === 'to' && fromDate 
            ? fromDate 
            : today;

        if (targetMonth.getFullYear() > minAllowedDate.getFullYear() || 
            (targetMonth.getFullYear() === minAllowedDate.getFullYear() && 
             targetMonth.getMonth() >= minAllowedDate.getMonth())) {
            setCurrentMonth(newMonth);
            setCurrentYear(newYear);
        }
    };

    // Handle clicks outside the calendar
    useEffect(() => {
        const handleClickOutside = (event) => {
            // Check if the click is outside both the calendar and the input
            if (
                calendarRef.current && !calendarRef.current.contains(event.target) &&
                inputRef.current && !inputRef.current.contains(event.target)
            ) {
                setIsCalendarOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // Prevent propagation to stop calendar from closing
    const handleCalendarClick = (e) => {
        e.stopPropagation();
    };

    // Render calendar
    const renderCalendar = () => {
        const calendarDays = generateCalendarDays();
        const monthNames = getMonthNames();

        return (
            <div
                ref={calendarRef}
                onClick={handleCalendarClick}
                className="absolute z-10 bg-white border rounded-lg shadow-lg p-4 w-64"
                style={{ top: '100%', left: 0 }}
            >
                {/* Calendar Header */}
                <div className="flex justify-between items-center mb-4">
                    <button
                        onClick={(e) => {
                            e.stopPropagation();
                            navigateMonth(-1);
                        }}
                        className=" hover:bg-gray-100 rounded"
                    >
                        ←
                    </button>
                    <div className="font-bold">
                        {monthNames[currentMonth]} {currentYear}
                    </div>
                    <button
                        onClick={(e) => {
                            e.stopPropagation();
                            navigateMonth(1);
                        }}
                        className="px-2 py-1 hover:bg-gray-100 rounded"
                    >
                        →
                    </button>
                </div>

                {/* Days of Week */}
                <div className="grid grid-cols-7 text-center font-semibold mb-2">
                    {['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'].map(day => (
                        <div key={day} className="text-xs">{day}</div>
                    ))}
                </div>

                {/* Calendar Days */}
                <div className="grid grid-cols-7 gap-1">
                    {calendarDays.map((day, index) => (
                        <button
                            key={index}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleDateSelect(day);
                            }}
                            className={`
                ${day ? 'hover:bg-red-100' : 'text-gray-300'}
                ${day === selectedDate.getDate() &&
                                    currentMonth === selectedDate.getMonth() &&
                                    currentYear === selectedDate.getFullYear()
                                    ? 'bg-red-600 text-white' : ''} 
                 py-1 rounded text-center
                ${!day ? 'cursor-not-allowed opacity-50' : ''}
              `}
                            disabled={!day}
                        >
                            {day || ''}
                        </button>
                    ))}
                </div>
            </div>
        );
    };

    // Format date for display
    const formatDate = (date) => {
        return date.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };

    return (
        <div
            className="relative w-64 flex border-2 items-center border-gray-400 rounded-md cursor-pointer"
            onClick={() => setIsCalendarOpen(!isCalendarOpen)}
        >
            <input
                ref={inputRef}
                type="text"
                value={formatDate(selectedDate)}
                readOnly
                className="w-full px-2 py-4 outline-none bg-transparent cursor-pointer"
            />
            <CalendarDays className="mr-2" />
            {isCalendarOpen && renderCalendar()}
        </div>
    );
};

export default CustomDatePicker;