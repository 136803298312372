import React, { useState, useCallback } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { MapPin, Calendar, ArrowRight, ChevronLeft, ChevronRight } from "lucide-react";

const destinations = [
  {
    id: 1,
    title: "Mystical Santorini",
    image: "https://d6pltnqzug32h.cloudfront.net/carouselhome/1.jpg",
    location: "Mukteshwar",
    price: "",
    gradient: "from-red-500 to-red-700",
  },
  {
    id: 2,
    title: "Mystical Santorini",
    image: "https://d6pltnqzug32h.cloudfront.net/carouselhome/2.jpg",
    location: "Mukteshwar",
    price: "",
    gradient: "from-red-500 to-red-700",
  },
  {
    id: 3,
    title: "Mystical Santorini",
    image: "https://d6pltnqzug32h.cloudfront.net/carouselhome/4.jpg",
    location: "Mukteshwar",
    price: "",
    gradient: "from-red-500 to-red-700",
  },
  {
    id: 4,
    title: "Mystical Santorini",
    image: "https://d6pltnqzug32h.cloudfront.net/carouselhome/3.jpg",
    location: "Mukteshwar",
    price: "",
    gradient: "from-red-500 to-red-700",
  },
  {
    id: 5,
    title: "Mystical Santorini",
    image: "https://d6pltnqzug32h.cloudfront.net/carouselhome/11.jpg",
    location: "Mukteshwar",
    price: "",
    gradient: "from-red-500 to-red-700",
  },
  {
    id: 6,
    title: "Mystical Santorini",
    image: "https://d6pltnqzug32h.cloudfront.net/carouselhome/9.jpg",
    location: "Mukteshwar",
    price: "",
    gradient: "from-red-500 to-red-700",
  },
  {
    id: 7,
    title: "Mystical Santorini",
    image: "https://d6pltnqzug32h.cloudfront.net/carouselhome/10.jpg",
    location: "Mukteshwar",
    price: "",
    gradient: "from-red-500 to-red-700",
  },
  {
    id: 8,
    title: "Mystical Santorini",
    image: "https://d6pltnqzug32h.cloudfront.net/carouselhome/12.jpg",
    location: "Mukteshwar",
    price: "",
    gradient: "from-red-500 to-red-700",
  },
  {
    id: 9,
    title: "Mystical Santorini",
    image: "https://d6pltnqzug32h.cloudfront.net/carouselhome/5.jpg",
    location: "Mukteshwar",
    price: "",
    gradient: "from-red-500 to-red-700",
  },
  {
    id: 10,
    title: "Mystical Santorini",
    image: "https://d6pltnqzug32h.cloudfront.net/carouselhome/13.jpg",
    location: "Mukteshwar",
    price: "",
    gradient: "from-red-500 to-red-700",
  },
  {
    id: 11,
    title: "Mystical Santorini",
    image: "https://d6pltnqzug32h.cloudfront.net/carouselhome/6.jpg",
    location: "Mukteshwar",
    price: "",
    gradient: "from-red-500 to-red-700",
  },
  {
    id: 12,
    title: "Mystical Santorini",
    image: "https://d6pltnqzug32h.cloudfront.net/carouselhome/7.jpg",
    location: "Mukteshwar",
    price: "",
    gradient: "from-red-500 to-red-700",
  },
  {
    id: 13,
    title: "Mystical Santorini",
    image: "https://d6pltnqzug32h.cloudfront.net/carouselhome/14.jpg",
    location: "Mukteshwar",
    price: "",
    gradient: "from-red-500 to-red-700",
  },
  {
    id: 14,
    title: "Mystical Santorini",
    image: "https://d6pltnqzug32h.cloudfront.net/carouselhome/15.jpg",
    location: "Mukteshwar",
    price: "",
    gradient: "from-red-500 to-red-700",
  },
  {
    id: 15,
    title: "Mystical Santorini",
    image: "https://d6pltnqzug32h.cloudfront.net/carouselhome/8.jpg",
    location: "Mukteshwar",
    price: "",
    gradient: "from-red-500 to-red-700",
  },
];

const slideVariants = {
  initial: { opacity: 0, x: 50, scale: 0.9 },
  animate: { opacity: 1, x: 0, scale: 1 },
  exit: { opacity: 0, x: -50, scale: 0.9 },
};

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [pause, setPause] = useState(false);

  const nextSlide = useCallback(
    () => setCurrentIndex((prev) => (prev + 1) % destinations.length),
    []
  );

  const prevSlide = useCallback(
    () => setCurrentIndex((prev) => (prev === 0 ? destinations.length - 1 : prev - 1)),
    []
  );

  React.useEffect(() => {
    if (pause) return;

    const slideInterval = setInterval(nextSlide, 3000);
    return () => clearInterval(slideInterval);
  }, [nextSlide, pause]);

  return (
    <div
      className="relative w-full aspect-video overflow-hidden bg-gray-900"
      onMouseEnter={() => setPause(true)}
      onMouseLeave={() => setPause(false)}
    >
      {/* Background Fallback */}
      <div className="absolute inset-0 bg-gradient-to-b from-gray-800 to-gray-900" />

      {/* Image Layer */}
      <AnimatePresence mode="wait">
        <motion.div
          key={currentIndex}
          className="absolute inset-0"
          variants={slideVariants}
          initial="initial"
          animate="animate"
          exit="exit"
          transition={{ duration: 0.8 }}
        >
          <img
            src={destinations[currentIndex].image}
            alt={destinations[currentIndex].title}
            className="w-full h-full object-contain object-top"
            loading="lazy"
          />
          <div className="absolute inset-0 bg-black/20" />
        </motion.div>
      </AnimatePresence>

      {/* Content Layer */}
      <div className="relative z-10 container mx-auto px-4 h-full flex items-center justify-center">
        <AnimatePresence mode="wait">
          <motion.div
            key={currentIndex}
            className="max-w-2xl text-white space-y-6 text-center"
            variants={slideVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            transition={{ duration: 0.8 }}
          >
            <div>
              <p className="text-base sm:text-xl lg:text-2xl font-medium text-gray-200 mb-2">{destinations[currentIndex].subtitle}</p>
              <h2 className="text-3xl sm:text-5xl lg:text-6xl font-bold mb-4">{destinations[currentIndex].title}</h2>
              <p className="text-sm sm:text-lg lg:text-xl text-gray-100">{destinations[currentIndex].description}</p>
            </div>
            <div className="flex justify-center space-x-4">
              <div className="flex items-center space-x-2 bg-white/20 px-4 py-2 rounded-full">
                <MapPin size={20} />
                <span>{destinations[currentIndex].location}</span>
              </div>
            </div>
            <div className="flex items-center justify-center space-x-4 mt-6">
              <button
                className={`px-4 sm:px-6 py-2 sm:py-3 rounded-full bg-gradient-to-r ${destinations[currentIndex].gradient} text-white font-semibold flex items-center space-x-2`}
              >
                <span className="text-sm sm:text-base">Book Now</span>
                <ArrowRight size={20} />
              </button>
              <span className="text-2xl font-bold">{destinations[currentIndex].price}</span>
            </div>
          </motion.div>
        </AnimatePresence>
      </div>

      {/* Navigation Buttons */}
      <div className="absolute z-20 top-1/2 transform -translate-y-1/2 w-full flex justify-between px-4">
        <button onClick={prevSlide} className="bg-white/20 p-3 rounded-full text-white">
          <ChevronLeft size={24} />
        </button>
        <button onClick={nextSlide} className="bg-white/20 p-3 rounded-full text-white">
          <ChevronRight size={24} />
        </button>
      </div>

      {/* Slide Indicators */}
      <div className="absolute bottom-8 left-1/2 transform -translate-x-1/2 flex space-x-2 z-20">
        {destinations.map((_, index) => (
          <button
            key={index}
            onClick={() => setCurrentIndex(index)}
            className={`w-3 h-3 rounded-full transition-all duration-300 ${
              index === currentIndex ? "bg-white w-8" : "bg-white/50"
            }`}
          />
        ))}
      </div>
    </div>
  );
};

export default React.memo(Carousel);