import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

function HomeBook() {
    const navigate = useNavigate();
    const [bookingData, setBookingData] = useState({
        checkIn: new Date(),
        checkOut: new Date(new Date().setDate(new Date().getDate() + 1)),
        guests: 1
    });

    const [isCheckInCalendarOpen, setIsCheckInCalendarOpen] = useState(false);
    const [isCheckOutCalendarOpen, setIsCheckOutCalendarOpen] = useState(false);
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [hoverDate, setHoverDate] = useState(null);

    const checkInCalendarRef = useRef(null);
    const checkOutCalendarRef = useRef(null);
    const checkInTriggerRef = useRef(null);
    const checkOutTriggerRef = useRef(null);

    // Custom date formatting
    const formatDate = (date) => {
        if (!date) return '';
        return date.toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric'
        });
    };

    // Generate calendar days for current month
    const generateCalendarDays = (date) => {
        const year = date.getFullYear();
        const month = date.getMonth();
        const firstDay = new Date(year, month, 1);
        const lastDay = new Date(year, month + 1, 0);
        const startingDay = firstDay.getDay();
        const days = [];

        for (let i = 0; i < startingDay; i++) {
            days.push(null);
        }

        for (let i = 1; i <= lastDay.getDate(); i++) {
            days.push(new Date(year, month, i));
        }

        return days;
    };

    const [calendarDays, setCalendarDays] = useState(generateCalendarDays(currentMonth));

    // Outside click handling
    useEffect(() => {
        const handleOutsideClick = (e) => {
            const checkInCalendarOpen =
                checkInCalendarRef.current &&
                !checkInCalendarRef.current.contains(e.target) &&
                checkInTriggerRef.current &&
                !checkInTriggerRef.current.contains(e.target);

            const checkOutCalendarOpen =
                checkOutCalendarRef.current &&
                !checkOutCalendarRef.current.contains(e.target) &&
                checkOutTriggerRef.current &&
                !checkOutTriggerRef.current.contains(e.target);

            if (checkInCalendarOpen) {
                setIsCheckInCalendarOpen(false);
            }

            if (checkOutCalendarOpen) {
                setIsCheckOutCalendarOpen(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    // Month change handler
    const changeMonth = (increment) => {
        const newMonth = new Date(currentMonth);
        newMonth.setMonth(newMonth.getMonth() + increment);
        setCurrentMonth(newMonth);
        setCalendarDays(generateCalendarDays(newMonth));
    };

    // Check-in selection handler
    const handleCheckInSelect = (date) => {
        const defaultCheckOut = new Date(date);
        defaultCheckOut.setDate(date.getDate() + 1);

        setBookingData(prev => ({
            ...prev,
            checkIn: date,
            checkOut: defaultCheckOut
        }));

        setTimeout(() => {
            setIsCheckInCalendarOpen(false);
            setIsCheckOutCalendarOpen(true);
        }, 0);
    };

    // Check-out selection handler
    const handleCheckOutSelect = (date) => {
        if (!bookingData.checkIn) {
            alert("Please select a check-in date first.");
            return;
        }

        if (date <= bookingData.checkIn) {
            alert("Check-out date must be after check-in date.");
            return;
        }

        setBookingData(prev => ({
            ...prev,
            checkOut: date
        }));

        setTimeout(() => {
            setIsCheckOutCalendarOpen(false);
        }, 0);
    };

    // Booking handler
    const handleBooking = () => {
        const formdata = {
            ...bookingData,
            checkIn: bookingData.checkIn ? bookingData.checkIn : null,
            checkOut: bookingData.checkOut ? bookingData.checkOut : null
        };
        navigate('/availability', { state: formdata });
    };

    // Helper function to check if a date is within the selected range
    const isDateInRange = (date) => {
        if (!bookingData.checkIn) return false;
        const checkOutDate = bookingData.checkOut || hoverDate;
        if (!checkOutDate) return false;

        const start = bookingData.checkIn < checkOutDate ? bookingData.checkIn : checkOutDate;
        const end = bookingData.checkIn < checkOutDate ? checkOutDate : bookingData.checkIn;

        return date > start && date < end;
    };

    // Calendar rendering
    const renderCalendar = (isCheckIn) => {
        const monthNames = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];

        return (
            <div
                ref={isCheckIn ? checkInCalendarRef : checkOutCalendarRef}
                className="absolute z-50 bg-white shadow-lg rounded-lg p-4 w-full sm:w-[300px]"
            >
                <div className="flex justify-between items-center mb-4">
                    <button
                        onClick={() => changeMonth(-1)}
                        className="px-2 py-1 bg-gray-200 rounded"
                    >
                        ←
                    </button>
                    <div className="font-bold">
                        {monthNames[currentMonth.getMonth()]} {currentMonth.getFullYear()}
                    </div>
                    <button
                        onClick={() => changeMonth(1)}
                        className="px-2 py-1 bg-gray-200 rounded"
                    >
                        →
                    </button>
                </div>
                <div className="grid grid-cols-7 text-center">
                    {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
                        <div key={day} className="font-bold text-sm">{day}</div>
                    ))}
                    {calendarDays.map((day, index) => {
                        const isCheckInSelected =
                            bookingData.checkIn &&
                            day &&
                            day.toDateString() === bookingData.checkIn.toDateString();

                        const isCheckOutSelected =
                            bookingData.checkOut &&
                            day &&
                            day.toDateString() === bookingData.checkOut.toDateString();

                        const isInRange = day && isDateInRange(day);

                        return (
                            <div
                                key={index}
                                className={`p-2 cursor-pointer
                                    ${!day ? 'text-gray-300' : 'hover:bg-red-100'}
                                    ${isCheckInSelected ? 'bg-red-600 text-white' : ''}
                                    ${isCheckOutSelected ? 'bg-red-600 text-white' : ''}
                                    ${isInRange ? 'bg-red-100' : ''}
                                    ${day && day < new Date() ? 'text-gray-400' : ''}
                                `}
                                onMouseEnter={() => {
                                    if (day && day >= new Date() && bookingData.checkIn && !bookingData.checkOut) {
                                        setHoverDate(day);
                                    }
                                }}
                                onMouseLeave={() => {
                                    setHoverDate(null);
                                }}
                                onClick={() => {
                                    if (day && day >= new Date()) {
                                        day && (isCheckIn ? handleCheckInSelect(day) : handleCheckOutSelect(day));
                                    }
                                }}
                            >
                                {day ? day.getDate() : ''}
                            </div>
                        );
                    })}
                </div>
                {!isCheckIn && bookingData.checkIn && (
                    <div className="text-center text-sm mt-2 text-gray-600">
                        Check-in: {formatDate(bookingData.checkIn)}
                    </div>
                )}
            </div>
        );
    };

    return (
        <div className='h-full w-full z-30 items-center bg-black/10 justify-center flex flex-col relative' >
            <div className='w-full h-full flex flex-col items-center justify-center'>
                <div className='text-center text-2xl mt-[80px] instrument text-white'>
                    <span className='text-6xl md:text-8xl pattaya'>Above the Clouds</span> <br />
                    <span className='text-2xl md:text-4xl'>Experience Elegant Retreats</span>
                </div>
                <div className='container mx-auto w-full flex flex-col items-center justify-center'>
                    <div className='py-3 px-3 w-full sm:w-[600px] md:w-[900px] flex gap-3 rounded-full mt-[40px] bg-white relative'>
                        <div
                            ref={checkInTriggerRef}
                            className='rounded-l-full py-2 border-r-[2px] cursor-pointer h-full w-full flex flex-col justify-evenly relative'
                            onClick={() => {
                                setIsCheckInCalendarOpen(true);
                                setIsCheckOutCalendarOpen(false);
                            }}
                        >
                            <p className='ml-[30px]'>Check In</p>
                            <p className='text-xs ml-[30px] text-gray-800'>
                                {formatDate(bookingData.checkIn)}
                            </p>
                            {isCheckInCalendarOpen && renderCalendar(true)}
                        </div>

                        <div
                            ref={checkOutTriggerRef}
                            className='h-full w-full py-2 border-r-[2px] cursor-pointer flex flex-col justify-evenly relative'
                            onClick={() => {
                                setIsCheckOutCalendarOpen(true);
                                setIsCheckInCalendarOpen(false);
                            }}
                        >
                            <p className='ml-[30px]'>Check Out</p>
                            <p className='text-xs ml-[30px] text-gray-800'>
                                {formatDate(bookingData.checkOut)}
                            </p>
                            {isCheckOutCalendarOpen && renderCalendar()}
                        </div>

                        <div className='h-full w-full py-2 flex items-center justify-evenly cursor-pointer'>
                            <div className='h-full w-full flex flex-col justify-evenly'>
                                <p className='ml-[30px]'>Guests</p>
                                <p className='text-xs ml-[30px] text-gray-800'>
                                    {'Select Guests'}
                                </p>
                            </div>
                            <div className='flex items-center justify-center gap-3 h-full w-full'>
                                <select
                                    value={bookingData.guests}
                                    onChange={(e) => setBookingData({ ...bookingData, guests: parseInt(e.target.value) })}
                                    className='px-4 py-2 text-xl before:rounded-md outline-none'
                                >
                                    {Array.from({ length: 8 }, (_, i) => i + 1).map(i => (
                                        <option key={i} value={i}>{i}</option>
                                    ))}
                                </select>

                            </div>
                        </div>

                        <div>
                            <button
                                onClick={handleBooking}
                                className='bg-red-600 hover:bg-red-700 text-white rounded-full py-3 px-8 w-full h-full'
                            >
                                Book
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomeBook;
