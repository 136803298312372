import React from 'react';
import CottageCard from './CottageCard';
import { cottagecarddata } from './cottagecarddata';

function CardFeed({ avail }) {
  const data = cottagecarddata;

  // Filter function based on availability (maxGuest >= avail.guests)
  const filterByGuests = (cottageList) => {
    if (avail && avail.guests) {
      return cottageList.filter(
        (cottage) => cottage.maxGuest + cottage.extbed >= avail.guests
      );
    }
    return cottageList; // If avail is not provided, return all cottages
  };

  // Filter studios and cottages based on avail.guests if avail exists
  const filteredStudios = filterByGuests(data.studios);
  const filteredCottages = filterByGuests(data.cottages);

  return (
    <div className='mx-auto container w-full'>
      {filteredStudios.length > 0 && (
        <div className='container mx-auto w-[90%] md:w-full'>
          <div className='py-[40px] pattaya text-4xl text-red-600 text-center md:text-start'>
            Studios & Apartments
          </div>
          <div className='ml-[20px] md:ml-0 flex h-[550px] overflow-x-scroll overflow-y-hidden pb-[50px] flex-nowrap items-center md:justify-start gap-12'>
            {filteredStudios.map((cottage, i) => (
              <CottageCard key={i} data={cottage} avail={avail}/>
            ))}
          </div>
        </div>
      )}
      {filteredCottages.length > 0 && (
        <div className='container mx-auto w-[90%] md:w-full'>
          <div className='py-[40px] pattaya text-4xl text-red-600 text-center md:text-start'>
            Independent Villas
          </div>
          <div className='ml-[20px] md:ml-0 flex h-[550px] overflow-x-scroll overflow-y-hidden pb-[50px] flex-nowrap items-center md:justify-start gap-12'>
            {filteredCottages.map((cottage, i) => (
              <CottageCard key={i} data={cottage} avail={avail}/>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default CardFeed;
