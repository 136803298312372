import React from 'react';
import { motion } from 'framer-motion';
import ivh1 from "../asset/ivh3.png";

const Splash = () => {
  return (
    <motion.div
      key="splash-background"
      className="fixed inset-0 z-50 bg-gradient-to-br from-red-100 via-orange-200 to-yellow-100 overflow-hidden"
      initial={{ opacity: 0 }} // Fade-in starts from 0 opacity
      animate={{ opacity: 1 }} // Fade-in ends at full opacity
      exit={{ opacity: 0 }} // Fade-out to 0 opacity
      transition={{ duration: 1 }} // Duration for both fade-in and fade-out
    >
      <div className="absolute inset-0 flex items-center justify-center">
        <motion.div
          initial={{
            scale: 0,
            rotate: 0,
            opacity: 0,
          }}
          animate={{
            scale: [1, 1, 1],
            opacity: [0, 0, 1],
          }}
          transition={{
            duration: 2,
            type: "spring",
            stiffness: 50,
          }}
          className="relative flex items-center justify-center"
        >
          <img className="h-36" src={ivh1} alt="Leaf" />
        </motion.div>
        {/* Background Leaf Particles */}
            {Array.from({ length: 20 }).map((_, index) => (
              <motion.div
                key={index}
                className="absolute"
                style={{
                  left: `${Math.random() * 100}%`,
                  top: `${Math.random() * 100}%`,
                  width: `${Math.random() * 30 + 10}px`,
                  height: `${Math.random() * 30 + 10}px`,
                }}
                initial={{
                  scale: 0,
                  opacity: 0,
                }}
                animate={{
                  scale: [0, 2.2, 2],
                  opacity: [0, 0.5, 0],
                }}
                transition={{
                  duration: Math.random() * 3 + 2,
                  repeat: Infinity,
                  repeatType: "loop",
                  delay: Math.random() * 2,
                }}
              >
                🍁
              </motion.div>
            ))}
      </div>
    </motion.div>
  );
};

export default Splash;

