import React from 'react';
// import Blogcard from "../components/Blogcard"

function Blog() {
  // const blogPosts = [
  //   {
  //     id: 1,
  //     title: 'Unveiling Urban Mysteries',
  //     excerpt: 'Discover the hidden stories that give life to urban landscapes.',
  //     date: 'Dec 15, 2024',
  //     readTime: '7 min read',
  //     image: 'https://d2ehq5aws28ia0.cloudfront.net/nearby/nr6.webp',
  //   },
  //   {
  //     id: 2,
  //     title: 'The Future of Creative AI',
  //     excerpt: 'Learn how AI is reshaping the boundaries of artistic innovation.',
  //     date: 'Nov 28, 2024',
  //     readTime: '9 min read',
  //     image: 'https://d2ehq5aws28ia0.cloudfront.net/nearby/nr6.webp',
  //   },
  // ];

  return (
    <>
    {/* Header Section */}
    <div className='w-full h-[40vh] md:h-[380px] flex flex-col' style={{
        backgroundImage: `url("https://d2ehq5aws28ia0.cloudfront.net/himalayan/1.jpg")`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    }}>
        <div className=' h-full w-full items-center justify-center flex flex-col'>
            <div className='w-full h-full flex flex-col items-center justify-center'>
                <div className='text-center text-2xl mt-[70px] instrument text-white
            '>
                    <span className='md:text-6xl text-4xl lg:text-8xl pattaya'>Blogs</span>
                </div>
            </div>

        </div>
    </div>
    {/* <Blogcard blogPosts={blogPosts}/> */}
    </>
  );
}

export default Blog;
