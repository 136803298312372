import { Wifi, UtensilsCrossed, CarFront, Flame } from 'lucide-react';

export const cottagecarddata = {
  studios: [
    {
      title: "studio_apartment",
      name: "1BHK Apartment",
      plot: "IVH 69",
      maxGuest: 2,
      extbed: 1,
      amenities: [
        { icon: Wifi, label: 'Wi-Fi' },
        { icon: CarFront, label: 'Parking' },
        { icon: UtensilsCrossed, label: 'Restaurant' },
        { icon: Flame, label: 'Fire Place' }
      ],
      price: "2000",
      images: [
        "https://d2ehq5aws28ia0.cloudfront.net/plots/plot69/2.jpg",
        "https://d2ehq5aws28ia0.cloudfront.net/project/5.jpg",
        "https://d2ehq5aws28ia0.cloudfront.net/plots/plot69/3.jpg",
      ],
      discount: "10%"
    },
    {
      title: "studio_apartment",
      name: "Studio Apartment",
      maxGuest: 2,
      extbed: 1,
      plot: "IVH 69 - F1",
      amenities: [
        { icon: Wifi, label: 'Wi-Fi' },
        { icon: CarFront, label: 'Parking' },
        { icon: UtensilsCrossed, label: 'Restaurant' },
        { icon: Flame, label: 'Fire Place' }
      ],
      price: "2000",
      images: [
        "https://d2ehq5aws28ia0.cloudfront.net/plots/plot69/1.jpg",
        "https://d6pltnqzug32h.cloudfront.net/cottages/4.jpg",
        "https://d2ehq5aws28ia0.cloudfront.net/plots/plot69/3.jpg",
      ],
      discount: "10%"
    },
    {
      title: "studio_apartmentf2",
      name: "Studio Apartment",
      maxGuest: 2,
      extbed: 1,
      plot: "IVH 69 - F2",
      amenities: [
        { icon: Wifi, label: 'Wi-Fi' },
        { icon: CarFront, label: 'Parking' },
        { icon: UtensilsCrossed, label: 'Restaurant' },
        { icon: Flame, label: 'Fire Place' }
      ],
      price: "2000",
      images: [
        "https://d6pltnqzug32h.cloudfront.net/cottages/f2/8.jpg",
        "https://d2ehq5aws28ia0.cloudfront.net/himalayan/1.jpg",
        "https://d6pltnqzug32h.cloudfront.net/cottages/f2/5.jpg",
      ],
      discount: "10%"
    },
    {
      title: "studio_apartmentf4",
      name: "Studio Apartment",
      maxGuest: 2,
      extbed: 1,
      plot: "IVH 69 - F4",
      amenities: [
        { icon: Wifi, label: 'Wi-Fi' },
        { icon: CarFront, label: 'Parking' },
        { icon: UtensilsCrossed, label: 'Restaurant' },
        { icon: Flame, label: 'Fire Place' }
      ],
      price: "2000",
      images: [
        "https://d6pltnqzug32h.cloudfront.net/cottages/f2/7.jpg",
        "https://d6pltnqzug32h.cloudfront.net/cottages/f2/4.jpg",
        "https://d6pltnqzug32h.cloudfront.net/cottages/3.jpg",
      ],
      discount: "10%"
    },

    {
      title: "1BHK_Apartment",
      name: "1BHK Apartment",
      maxGuest: 2,
      extbed: 1,
      plot: "IVH 09",
      amenities: [
        { icon: Wifi, label: 'Wi-Fi' },
        { icon: CarFront, label: 'Parking' },
        { icon: UtensilsCrossed, label: 'Restaurant' },
        { icon: Flame, label: 'Fire Place' }
      ],
      price: "4000",
      "images": [
        "https://d2ehq5aws28ia0.cloudfront.net/plots/plot09/3.jpg",
        "https://d2ehq5aws28ia0.cloudfront.net/plots/plot09/8.jpg",
        "https://d2ehq5aws28ia0.cloudfront.net/project/2.jpg",
      ],
      discount: "10%"
    },
  ],
  cottages: [
    {
      title: "1BHK_villa",
      name: "1BHK Villa",
      plot: "IVH 42",
      maxGuest: 2,
      extbed: 1,
      amenities: [
        { icon: Wifi, label: 'Wi-Fi' },
        { icon: CarFront, label: 'Parking' },
        { icon: UtensilsCrossed, label: 'Restaurant' },
        { icon: Flame, label: 'Fire Place' }
      ],
      price: "5000",
      images: [
        "https://d6pltnqzug32h.cloudfront.net/cottages/42/1.jpg",
        "https://d6pltnqzug32h.cloudfront.net/cottages/42/3.jpg",
        "https://d6pltnqzug32h.cloudfront.net/cottages/5.jpg"
      ],
      discount: "10%"
    },
    {
      title: "2BHK_villa",
      name: "2BHK Villa",
      maxGuest: 4,
      extbed: 2,
      plot: "IVH 47",
      amenities: [
        { icon: Wifi, label: 'Wi-Fi' },
        { icon: CarFront, label: 'Parking' },
        { icon: UtensilsCrossed, label: 'Restaurant' },
        { icon: Flame, label: 'Fire Place' }
      ],
      price: "7000",
      images: [
        "https://d6pltnqzug32h.cloudfront.net/cottages/cot47/5.jpg",
        "https://d6pltnqzug32h.cloudfront.net/cottages/cot47/6.jpg",
        "https://d6pltnqzug32h.cloudfront.net/cottages/cot47/1.jpg"
      ],
      discount: "10%"
    },
    {
      title: "4BHK_villa",
      name: "4BHK Villa",
      maxGuest: 8,
      extbed: 4,
      plot: "IVH 43",
      amenities: [
        { icon: Wifi, label: 'Wi-Fi' },
        { icon: CarFront, label: 'Parking' },
        { icon: UtensilsCrossed, label: 'Restaurant' },
        { icon: Flame, label: 'Fire Place' }
      ],
      price: "12000",
      "images": [
        "https://d2ehq5aws28ia0.cloudfront.net/plots/plot43/1.jpg",
        "https://d2ehq5aws28ia0.cloudfront.net/drone/1.jpg",
        "https://d2ehq5aws28ia0.cloudfront.net/plots/plot43/8.jpg",
      ],
      discount: "10%"
    },
  ]
}