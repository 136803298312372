import React from 'react';
import MarqueeCus from '../components/MarqueeCus';

const About = () => {
  return (
    <div className=" text-gray-900 font-sans">
      {/* Header Section */}
      <div className='w-full h-[40vh] md:h-[380px] flex flex-col' style={{
                    backgroundImage: `url("https://d2ehq5aws28ia0.cloudfront.net/himalayan/4.jpg")`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                }}>
                    <div className=' h-full w-full items-center justify-center flex flex-col'>
                        <div className='w-full h-full flex flex-col items-center justify-center'>
                            <div className='text-center text-2xl mt-[70px] text-white
                        '>
                                <span className='md:text-6xl text-4xl lg:text-8xl pattaya'>About Us</span> <br /> <span className='text-base md:text-xl text-white font-medium mt-[20px] lg:text-4xl'>Explore Breathtaking Stay With Us</span>
                            </div>
                        </div>

                    </div>
                </div>
      
      {/* Header Section */}
      <section className="text-center max-w-7xl mx-auto text-black mt-12">
        <h1 className="text-4xl px-3 font-bold text-red-600 mb-4 pattaya">Indus Valley Homestay: Your Perfect Retreat in Mukteshwar</h1>
        <p className="text-lg text-center px-3 max-w-7xl mx-auto">
        Indus Valley Homestay, the best hotel in Mukteshwar, offers a peaceful retreat with breathtaking panoramic views of the Nanda Devi Himalayas. This resort combines modern comforts with rustic charm, providing cozy rooms and delicious food. As an ideal homestay, it's perfect for both relaxation and adventure.
        </p>
      </section>

      {/* Accommodations Section */}
<section className="mt-12 max-w-7xl mx-auto">
  <h2 className="text-4xl font-semibold text-red-600 text-center mb-8 pattaya">Accommodations and Amenities</h2>

  {/* Grid for 2x2 layout */}
  <div className="grid grid-cols-1 gap-5">
    {/* Cozy Rooms and Villas */}
    <div className="px-6 rounded-lg transition-shadow duration-300">
      <h3 className="text-2xl font-semibold dmserif mb-4">Cozy Rooms and Villas</h3>
      <ul className=" pl-6 space-y-1">
        <li>Whether you're seeking the intimacy of a 1 BHK apartment or the spaciousness of a 2, 3, or 4 BHK villa, we have the ideal accommodation tailored to your needs.
        Our accommodations include couple-friendly and pet-friendly options, each with personal parking spaces.</li>
      </ul>
    </div>

    {/* Security */}
    <div className="px-6 rounded-lg transition-shadow duration-300">
      <h3 className="text-2xl font-semibold dmserif mb-4">Security</h3>
      <ul className="pl-6 space-y-3">
        <li>Your safety is our top priority. We have two vigilant guards working round-the-clock to ensure your peace of mind during your stay.
Our gated township adds an extra layer of security, coupled with secured barricades for added protection.
24/7 Assistance: Our friendly team is always available to assist you throughout your stay.</li>
      </ul>
    </div>

    {/* Convenience */}
    <div className="px-6 rounded-lg transition-shadow duration-300">
      <h3 className="text-2xl font-semibold dmserif mb-4">Convenience</h3>
      <ul className=" pl-6 space-y-3">
        <li>Order groceries and essentials right from your room.</li>
      </ul>
    </div>

    {/* Clubhouse */}
    <div className="px-6 rounded-lg transition-shadow duration-300">
      <h3 className="text-2xl font-semibold dmserif mb-4">Clubhouse</h3>
      <ul className="pl-6 space-y-3">
        <li>Recreational Activities: Explore our clubhouse with open green areas for badminton, kabaddi, and volleyball.
Cozy Bonfire Evenings: When the sun goes down, join us around the bonfire for warm and cozy evenings.
Musical Nights: Enjoy unforgettable musical nights that will add an extra touch of magic to your stay.</li>
      </ul>
    </div>
  </div>
</section>


      {/* Activities Section */}
      <section className="px-6 mt-12 ">
        <h2 className="text-4xl font-semibold text-red-600 text-center mb-8 pattaya">Activities</h2>
        <p className="text-lg max-w-7xl mx-auto text-center">
        Guests can enjoy trekking, bird watching, exploring lush orchards, and visiting local attractions like Mukteshwar Temple, Chauli Ki Jali, and Bhalu Gaad Waterfalls. Additionally, there's a 5-10 km trek path available from the resort leading towards a forest and a beautiful small lake.
        </p>
      </section>

      {/* Hillcrest Cafe Section */}
      <section className="px-6 mt-12">
        <h2 className="text-4xl font-semibold text-red-600 text-center mb-8 pattaya">Hillcrest Cafe</h2>
        <p className="text-lg max-w-7xl mx-auto text-center">
        Hillcrest Cafe, the best cafe and a standout eatery in Mukteshwar, offers a delightful dining experience with panoramic views of the Nanda Devi Himalayas. As a premier restaurant in Mukteshwar, guests can savor a variety of delicious local and Indian dishes made from fresh, locally sourced ingredients. The cozy ambiance and stunning scenery makes Hillcrest Cafe the perfect spot to relax and enjoy a memorable meal during your stay in Mukteshwar Nainital. 
        We at Indus Valley Resort are proud to be the best restaurant in Mukteshwar, serving delicious food in an amazing location.
        </p>
      </section>

      {/* Events Section */}
      <section className="px-6 my-12">
        <h2 className="text-4xl font-semibold text-red-600 text-center mb-8 pattaya">Events and Celebrations</h2>
        <p className="text-lg max-w-7xl mx-auto text-center">
        Indus Valley Mukteshwar Homestay offers a range of top-notch services and amenities to ensure a memorable stay. Guests can take advantage of the best venue in Mukteshwar for hosting events. This versatile event place in Mukteshwar serves as an ideal celebration venue and party place. Whether you're planning a special celebration or a casual get-together, our facilities provide the perfect setting.
        Indus Valley Homestay in Mukteshwar is the perfect spot for people who feel at home amidst nature and outdoor enthusiasts. With its blend of comfort, adventure, and stunning Himalayan scenery, your stay in Mukteshwar Nainital promises to be truly unforgettable. Come and experience the best of Mukteshwar with us!
        </p>
      </section>
      <MarqueeCus/>
    </div>
  );
};

export default About;
