import React, { useState } from 'react';

const Nearby = () => {
    const [activeFilter, setActiveFilter] = useState('all');

    const categories = {
        nature: { label: 'Nature' },
        adventure: { label: 'Adventure' },
        spiritual: { label: 'Spiritual' },
        citylife: { label: 'City Life' },
    };

    const destinations = [
        {
            name: "Forest Trekking",
            distance: 0,
            image: "https://d2ehq5aws28ia0.cloudfront.net/nearby/nr1.webp",
            category: "nature",
            alt: "Forest Trekking in Mukteshwar",
        },
        {
            name: "Bhalu Gaad Waterfall",
            distance: 12,
            image: "https://d2ehq5aws28ia0.cloudfront.net/nearby/nr2.webp",
            category: "nature",
            alt: "Bhalu Gaad Waterfall",
        },
        {
            name: "Mahadev Temple",
            distance: 15,
            image: "https://d2ehq5aws28ia0.cloudfront.net/nearby/nr3maha.png",
            category: "spiritual",
            alt: "Mahadev Temple Mukteshwar",
        },
        {
            name: "Satkhol Ashram",
            distance: 26,
            image: "https://d2ehq5aws28ia0.cloudfront.net/nearby/nr4.webp",
            category: "spiritual",
            alt: "Satkhol Ashram",
        },
        {
            name: "Bhimtal",
            distance: 31,
            image: "https://d2ehq5aws28ia0.cloudfront.net/nearby/nr5.webp",
            category: "citylife",
            alt: "Bhimtal Lake",
        },
        {
            name: "Paragliding Adventure",
            distance: 35,
            image: "https://d2ehq5aws28ia0.cloudfront.net/nearby/nr6.webp",
            category: "adventure",
            alt: "Paragliding in Nainital",
        },
        {
            name: "Mall Road Nainital",
            distance: 46,
            image: "https://d2ehq5aws28ia0.cloudfront.net/nearby/nr7.webp",
            category: "citylife",
            alt: "Mall Road Nainital",
        },
        {
            name: "Almora",
            distance: 59,
            image: "https://d2ehq5aws28ia0.cloudfront.net/nearby/nr8.webp",
            category: "citylife",
            alt: "Almora",
        },
        {
            name: "Jageshwar Dham",
            distance: 65,
            image: "https://d2ehq5aws28ia0.cloudfront.net/nearby/nr9.webp",
            category: "spiritual",
            alt: "Jageshwar Dham",
        },
        {
            name: "Ranikhet",
            distance: 80,
            image: "https://d2ehq5aws28ia0.cloudfront.net/nearby/nr10.webp",
            category: "nature",
            alt: "Ranikhet",
        },
        {
            name: "Binsar",
            distance: 80,
            image: "https://d2ehq5aws28ia0.cloudfront.net/nearby/nr11.webp",
            category: "nature",
            alt: "Binsar",
        },
        {
            name: "Kausani",
            distance: 108,
            image: "https://d2ehq5aws28ia0.cloudfront.net/nearby/nr12.webp",
            category: "nature",
            alt: "Kausani",
        },
    ];


    const filteredDestinations = activeFilter === 'all'
        ? destinations
        : destinations.filter((dest) => dest.category === activeFilter);

    return (
        <>
            <div className="min-h-screen bg-gradient-to-r  text-gray-800">
                {/* Header Section */}
                <div className='w-full h-[40vh] md:h-[380px] flex flex-col' style={{
                    backgroundImage: `url("https://d2ehq5aws28ia0.cloudfront.net/himalayan/3.jpg")`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                }}>
                    <div className=' h-full w-full items-center justify-center flex flex-col'>
                        <div className='w-full h-full flex flex-col items-center justify-center'>
                            <div className='text-center text-2xl mt-[70px] text-white
                        '>
                                <span className='md:text-6xl text-4xl lg:text-8xl pattaya'>Discover Nearby Wonders</span> <br /> <span className='text-base md:text-xl text-white font-medium mt-[20px] lg:text-4xl'>Explore breathtaking destinations around you</span>
                            </div>
                        </div>

                    </div>
                </div>

                {/* Filter Section */}
                <div className="py-8">
                    <div className="container w-[95%] md:w-full mx-auto flex flex-wrap justify-center gap-4">
                        <button
                            onClick={() => setActiveFilter('all')}
                            className={`px-8 py-3 rounded-full font-semibold transition-transform duration-300 ${activeFilter === 'all' ? 'bg-gradient-to-r from-red-500 to-red-600 text-white shadow-xl scale-110' : 'bg-transparent text-red-600 border-2 border-red-600 hover:bg-red-600 hover:text-white hover:scale-105'
                                }`}
                        >
                            All
                        </button>
                        {Object.entries(categories).map(([key, { label }]) => (
                            <button
                                key={key}
                                onClick={() => setActiveFilter(key)}
                                className={`px-8 py-3 rounded-full font-semibold transition-transform duration-300 ${activeFilter === key ? 'bg-gradient-to-r from-red-500 to-red-600 text-white shadow-xl scale-110' : 'bg-transparent text-red-600 border-2 border-red-600 hover:bg-red-600 hover:text-white hover:scale-105'
                                    }`}
                            >
                                {label}
                            </button>
                        ))}
                    </div>
                </div>

                {/* Destinations Section */}
                <div className="max-w-screen-xl mx-auto px-4 py-8 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
                    {filteredDestinations.map((destination, index) => (
                        <a
                            key={index}
                            href={`https://www.google.com/maps?q=${destination.name}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="block group relative rounded-lg overflow-hidden transition-all duration-500"
                        >
                            {/* Card Container */}
                            <div className="relative bg-white shadow-lg rounded-lg overflow-hidden transition-transform duration-500 group-hover:scale-105 group-hover:shadow-xl">

                                {/* Image with smooth hover effect */}
                                <img
                                    src={destination.image}
                                    alt={destination.alt}
                                    className="w-full h-[300px] object-cover transition-all duration-500 group-hover:scale-110 rounded-t-lg"
                                />

                                {/* Card Content */}
                                <div className="absolute bottom-0 left-0 right-0 p-6 bg-gradient-to-t from-black via-black to-transparent opacity-80 group-hover:opacity-100 transition-opacity duration-300">
                                    <h3 className="text-xl text-white font-bold">{destination.name}</h3>
                                    <p className="text-sm text-white mt-2">
                                        {destination.distance === 0 ? 'On location' : `${destination.distance} KM away`}
                                    </p>
                                </div>

                                {/* Hover Badge */}
                                <div className="absolute top-4 right-4 bg-red-600 text-white text-xs px-4 py-2 rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                    {destination.distance} KM
                                </div>
                            </div>

                            {/* Glowing border effect */}
                            <div className="absolute inset-0 rounded-lg group-hover:border-4 group-hover:border-red-500 group-hover:border-opacity-70 transition-all duration-300"></div>
                        </a>
                    ))}
                </div>


            </div>
        </>
    );
};

export default Nearby;
