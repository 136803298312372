import React, { useState, useEffect } from 'react';
import ImageCarousel from './ImageCarousel';
import CustomDatePicker from './CustomDatePicker';
import { IndianRupee, UsersRound, CalendarDays, User, X } from 'lucide-react';
import { useLocation, useParams } from 'react-router-dom';
import { cottageData } from './cottageData';
import Amenities from '../../screens/Amenities';
import BookingConfirmation from './BookingConfirmation';

function RenderCottage() {
    const { title } = useParams();
    const data = cottageData[title];
    const location = useLocation();
    const availa = location.state;

    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);

    const [adults, setAdults] = useState(1); // Default to 1 if no availa
    const [extrabed, setExtraBed] = useState(0); // Default to 0 if no availa
    const [fromDate, setFromDate] = useState(availa?.checkIn ? new Date(availa.checkIn) : today);
    const [toDate, setToDate] = useState(availa?.checkOut ? new Date(availa.checkOut) : tomorrow);
    const [formData, setFormData] = useState(null); // Manage form data for booking
    const [Book, setBook] = useState(false); // Control booking modal

    // Set guest and extra bed only if availa prop is available
    useEffect(() => {
        if (availa) {
            const ext = availa.guests ? availa.guests - data.maxguest : 0;
            const guest = ext > 0 ? availa.guests - ext : availa.guests;
            setAdults(guest != null ? guest : 1); // Ensure valid guest value
            setExtraBed(ext >= 0 ? ext : 0);
        }
    }, [availa, data.maxguest]);

    // Manage scroll behavior
    useEffect(() => {
        if (Book) {
            document.body.classList.add('overflow-hidden');
        } else {
            document.body.classList.remove('overflow-hidden');
        }
        return () => document.body.classList.remove('overflow-hidden'); // Cleanup
    }, [Book]);

    // Calculate number of nights
    const calculateNights = () => {
        if (fromDate && toDate) {
            const diffTime = Math.abs(toDate - fromDate);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            return diffDays;
        }
        return 0;
    };

    // Calculate total price
    const calculateTotalPrice = () => {
        return calculateNights() * data.price;
    };

    // Book Now function to log form data
    function bookNow() {
        const newFormData = {
            title: data.title,
            adults: adults,
            extrabed: extrabed,
            fromDate: fromDate,
            toDate: toDate,
            totalPrice: calculateTotalPrice(),
        };

        setFormData(newFormData);   // Log the form data when the button is clicked
        setBook(true);             // Toggle the booking modal visibility
    }

    return (
        <>
            <div>
                {/* Header Section */}
                <div className="w-full h-[100px]"></div>
                <div className="container mx-auto mb-[120px]">

                    {/* Image and Details */}
                    <div className="flex gap-5">
                        <ImageCarousel images={data.images} />
                        <div className="mt-[5px] h-full w-full">
                            <p className="text-4xl font-bold dmserif text-gray-800">{data.title}</p>
                            <p className="text-xl font-semibold text-gray-500 mt-[10px]">{data.plot}</p>

                            <div className="flex h-full w-full gap-3 mt-[20px]">
                                <div>
                                    <p className="text-lg text-gray-500 font-semibold">From</p>
                                    <CustomDatePicker
                                        type="from"
                                        onDateChange={(date) => setFromDate(date)}
                                        selectedDate={fromDate}
                                    />
                                </div>
                                <div className="w-full">
                                    <p className="text-lg text-gray-500 w-full font-semibold ml-[20px]">Adults</p>
                                    <div className="border-2 border-gray-400 rounded-md">
                                        <select
                                            className="w-full px-2 py-[17px] bg-transparent border-none outline-none cursor-pointer"
                                            value={adults}
                                            onChange={(e) => setAdults(Math.max(1, Number(e.target.value)))} // Ensure minimum 1
                                        >
                                            {Array.from({ length: data.maxguest }, (_, i) => (
                                                <option key={i + 1} value={i + 1}>
                                                    {i + 1}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="flex h-full w-full gap-3 mt-[20px]">
                                <div>
                                    <p className="text-lg text-gray-500 font-semibold">To</p>
                                    <CustomDatePicker
                                        type="to"
                                        fromDate={fromDate}
                                        onDateChange={(date) => setToDate(date)}
                                        selectedDate={toDate}
                                    />
                                </div>
                                <div className="w-full">
                                    <p className="text-lg text-gray-500 w-full font-semibold ml-[20px]">Extra Bed</p>
                                    <div className="border-2 border-gray-400 rounded-md">
                                        <select
                                            className="w-full px-2 py-[17px] bg-transparent border-none outline-none cursor-pointer"
                                            value={extrabed}
                                            onChange={(e) => setExtraBed(Number(e.target.value))}
                                        >
                                            {Array.from({ length: data.extbed }, (_, i) => (
                                                <option key={i} value={i+1}>
                                                    {i+1}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="mt-[30px] h-full w-full flex justify-between">
                                <div>
                                    <div className="flex gap-5">
                                        <p className="flex gap-2 text-gray-600 items-center text-2xl"><UsersRound /> {adults}</p>
                                        <p className="flex gap-2 text-gray-600 items-center text-2xl"><User /> {extrabed}</p>
                                    </div>
                                    <p className="flex gap-2 text-gray-600 items-center mt-[10px] text-xl">
                                        <CalendarDays />
                                        {calculateNights() > 1 ? `${calculateNights()} nights` : `${calculateNights()} night`}
                                    </p>
                                    <div className="flex items-center mt-[10px] text-2xl font-bold text-gray-600 mb-2">
                                        <IndianRupee size={24} className="mr-1" />
                                        {calculateTotalPrice()}
                                    </div>
                                </div>
                                <div className="flex flex-col justify-end">
                                    <div className="flex items-center text-2xl font-bold text-gray-800 mb-2">
                                        <IndianRupee size={24} className="mr-1" />
                                        {data.price}
                                        <span className="text-sm text-gray-500 ml-1">/ night</span>
                                    </div>
                                    <button onClick={bookNow} className="bg-red-600 text-white py-[10px] px-[20px] rounded-md font-bold hover:bg-red-700">Reserve</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Description Section */}
                    <section className="ml-[15px]">
                        <p className="text-4xl font-bold mt-[20px] dmserif text-start mb-3 text-gray-800">{data.title}</p>
                        <p className="text-justify">
                            {data.summary}
                        </p>
                    </section>

                    {/* Amenities Section */}
                    <div className="ml-[15px]">
                        <h2 className="text-3xl mt-[40px] font-bold text-start mb-8 pattaya text-red-600">Amenities</h2>
                        <Amenities />
                    </div>
                </div>
            </div>

            {/* Booking Confirmation Modal */}
            {Book && formData && (
                <div className="fixed top-0 mx-auto z-50 left-0 bg-gray-800/95 h-[100vh] w-full p-8">
                    <button
                        onClick={() => setBook(false)}
                        className="fixed z-40 top-6 right-6 text-gray-700 bg-gray-300 hover:bg-gray-400 p-3 rounded-full shadow-lg"
                    >
                        <X size={24} />
                    </button>
                    <div className='container mx-auto h-full w-full'>
                        <BookingConfirmation data={formData} />
                    </div>
                </div>
            )}
        </>
    );
}

export default RenderCottage;
