import React from 'react';
import MenuRes from '../components/MenuRes';

function Dining() {
  return (
    <div className="w-full">
      {/* Header Section */}
      <div className='w-full h-[40vh] md:h-[450px] flex flex-col' style={{
        backgroundImage: `url("https://d6pltnqzug32h.cloudfront.net/dineinheader.jpeg")`,
        backgroundSize: 'cover',
        backgroundPosition: 'bottom 20% center',
        backgroundRepeat: 'no-repeat',
      }}>
        <div className='h-full w-full items-center justify-center flex flex-col'>
          <div className='w-full h-full flex flex-col items-center justify-center'>
            <div className='text-center text-2xl mt-[70px] text-white'>
              <p className='md:text-4xl text-4xl lg:text-8xl text-white pattaya'>Hillcrest Cafè</p>
              <p className='text-base md:text-xl text-white font-medium mt-[20px] lg:text-4xl'>Enjoy Your Food With Breathtaking View</p>
            </div>
          </div>
        </div>
      </div>

      {/* Welcome Section */}
      <div className="container mx-auto mt-[80px] flex flex-col gap-5 px-5">
        <div className="flex flex-col md:flex-row justify-center items-center gap-5">
          {/* Image Section */}
          <div className="w-full md:w-1/2 flex justify-center md:justify-end">
            <img
              src="https://d6pltnqzug32h.cloudfront.net/welcome.png"
              className="object-contain h-[400px] md:h-[600px] w-full"
              alt="Welcome to Hillerest Cafe"
            />
          </div>

          {/* Text Section */}
          <div className="w-full md:w-1/2 flex flex-col items-center md:items-start justify-start">
            <div className='w-[80%]'>
              <p className="text-2xl pattaya text-red-600">Hillcrest Cafe</p>
              <h1 className="text-3xl md:text-6xl font-bold text-gray-800 my-[10px]">Welcome</h1>
              <p className="text-justify text-base md:text-lg">
                Welcome to Indus Valley Resort in Mukteshwar, Uttarakhand. Situated against the stunning backdrop of the
                Nanda Devi mountain range, our luxury resort offers a perfect blend of comfort and elegance. Whether it's
                accommodation or dining you seek, we have it all.<br /><br /> Our homestay in Mukteshwar is not just a place to stay but
                a destination for memorable gatherings and celebrations. With ample outdoor space, we cater to private parties,
                ensuring an exclusive experience for you and your guests at our restaurant in Mukteshwar.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container mx-auto mt-12 md:mt-0 flex flex-col gap-5 px-5">
        <div className="flex flex-col-reverse md:flex-row justify-center items-center gap-5">
          {/* Text Section */}
          <div className="w-full md:w-1/2 flex flex-col items-center md:items-end">
            <div className='w-[80%]'>
              <p className="text-3xl md:text-6xl pattaya text-red-600">Hillcrest Cafe</p>
              <br />
              <p className="text-justify text-base md:text-lg">
                Savor the authentic taste of home-cooked meals seasoned with unique Pahadi spices at our restaurant. Our chefs are passionate about crafting local delicacies and personalized dishes to fulfill your desires. Enjoy your dining experience with stunning panoramic mountain views enhancing the ambiance.
                <br /><br />
                So, if you're seeking a cozy cafe in Mukteshwar to unwind, Indus Valley Mukteshwar Resort welcomes you to a world of luxury and tranquility amidst the beauty of Uttarakhand.
              </p>
            </div>
          </div>

          {/* Image Section */}
          <div className="w-full md:w-1/2 flex justify-center md:justify-end">
            <img
              src="https://d6pltnqzug32h.cloudfront.net/dinein2.png"
              className="object-contain h-[400px] md:h-[600px] w-full"
              alt="Welcome to Hillerest Cafe"
            />
          </div>
        </div>
      </div>

      {/* Food Image */}
      <div className="h-[400px] md:h-[600px] w-full flex mt-[40px] flex-col items-center justify-center">
        <img className='h-full w-full object-cover object-bottom' src="https://d6pltnqzug32h.cloudfront.net/food.jpg" alt="" />
      </div>

      {/* Menu Section */}
      <div className="w-full mt-10 mb-[40px] px-5">
        <h2 className="text-4xl md:text-6xl text-center pattaya text-red-600">Our Menu</h2>
        <MenuRes />
      </div>

    </div>
  );
}

export default Dining;
